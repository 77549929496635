import React, {Component} from 'react';
import {Form, Button} from 'react-bootstrap';
import {AuthContext} from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Notification from '../components/Notification';
import PublicApi from '../services/PublicApi';

export default class Login extends Component {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);

        this.publicApi = new PublicApi();

        this.login = this.login.bind(this);
        this.changeHandler = this.changeHandler.bind(this);

        this.state = {
            value: '',
            redirectOnSuccess: false
        };

        if (this.props.toastMessage) {
            this.setState({
                toastMessage: this.props.toastMessage
            });
        }
    }

    async login(event) {
        event.preventDefault();
        const password = this.state.value;
        const response = await this.publicApi.login(password);

        if (response.loggedin) {
            const {setAuthInfo} = this.context;
            setAuthInfo({
                isLoggedIn: true,
                expiresAt: Date.now(),
                userInfo: {
                    // TODO: root || last visited (stored somewhere in DB)
                    currentCategory: 1
                }
            });

            this.setState({
                redirectOnSuccess: true
            });
        } else {
            this.setState({
                wrongPassword: true
            });
        }
    }

    changeHandler(event) {
        this.setState({value: event.target.value});
    }

    render() {
        if (this.state.redirectOnSuccess || this.context.consideredAuthenticated()) {
            return (<Navigate to={{ pathname: '/home' }} />);
        }

        return (
            <>
                {
                    this.state.wrongPassword &&
                        <Notification
                            header={'Incorrect password'}
                            body={'Password is not correct.'}
                            onClose={() => this.setState({
                                wrongPassword: false
                            })}
                        />
                }
                <div className={'jumbotron-container'}>
                    <h1>You are not logged in</h1>
                    <Form onSubmit={this.login}>
                        <Form.Group className="mb-3">
                            <Form.Label>Enter password</Form.Label>
                            <Form.Control
                                type='password'
                                placeholder='Enter password'
                                id={'password'}
                                value={this.state.value}
                                onChange={this.changeHandler}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Log in
                        </Button>
                    </Form>
                </div>
            </>
        );
    }
}

Login.propTypes = {
    location: PropTypes.object,
    toastMessage: PropTypes.string,
    route: PropTypes.object
};
