import PropTypes from 'prop-types';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import React from 'react';

export default function ButtonTooltip(props) {
    const { disabled, tooltipText, variant, onClick, children } = props;
    let button = (
        <Button
            variant={variant}
            onClick={onClick}
            disabled={disabled}>
            {children}
        </Button>
    );
    let result = (
        <OverlayTrigger
            overlay={<Tooltip id='tooltip-disabled'>{tooltipText}</Tooltip>}>
            <span className='d-inline-block'>
                {button}
            </span>
        </OverlayTrigger>
    );

    if (!disabled) {
        result = button;
    }

    return result;
}

ButtonTooltip.propTypes = {
    disabled: PropTypes.bool,
    tooltipText: PropTypes.string,
    children: PropTypes.node,
    variant: PropTypes.string,
    onClick: PropTypes.func
};

