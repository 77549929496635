import React, {Component} from 'react';
import ReactDOM from 'react-dom/client';

// Routing
import {
    HashRouter,
    Routes,
    Route,
} from 'react-router-dom';

import Container from 'react-bootstrap/Container';

import Navigation from './components/Navigation';
import './index.css';
import Home from './routes/Home';
import About from './routes/About';
import Login from './routes/Login';
import {AuthProvider} from './context/AuthContext';

export default class App extends Component {
    render() {
        return (
            <React.StrictMode>
                <HashRouter>
                    <AuthProvider>
                        <Navigation/>
                        <Container>
                            <Routes>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/home" element={<Home/>}/>
                                <Route path="/login" element={<Login/>}/>
                                <Route path="/about" element={<About/>}/>
                            </Routes>
                        </Container>
                    </AuthProvider>
                </HashRouter>
            </React.StrictMode>
        );
    }
}

const root = ReactDOM.createRoot(
    document.getElementById('root')
);


root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
