import React, {Component} from 'react';
import {AuthContext} from '../context/AuthContext';
import {Navigate} from 'react-router-dom';
import Tree from '../components/Tree';
import Carousel from '../components/Carousel';
import {Button, Col, Row, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faArrowDown,
    faArrowUp,
    faPlusCircle,
    faSquareRootAlt,
    faTrash,
    faTree,
    faWrench
} from '@fortawesome/free-solid-svg-icons';
import ConfirmDialog from '../components/ConfirmDialog';
import CategoryForm from '../components/CategoryForm';
import Notification from '../components/Notification';
import ButtonTooltip from '../components/ButtonTooltip';

export default class Home extends Component {

    static contextType = AuthContext;

    constructor(props, context) {
        super(props, context);

        this.changeHandler = this.changeHandler.bind(this);

        this.state = {
            value: '',
            codes: [],
            text: [],
            categories: [],
            deleteCategoryConfirmationDialogShown: false,
            categoryFormData: {},
            categoryFormShown: false,
            moveCategoryMode: false,
            graph: {
                root: null,
                edges: [],
                pseudoRoots: []
            },
            curCategory: parseInt(localStorage.getItem('lastCategoryIdx')),
            languages: []
        };
    }

    changeHandler(event) {
        this.setState({value: event.target.value});
    }

    async loadData() {
        // fetching of private endpoint without being authenticated (no valid SID) will result
        // in redirect to login page by default behaviour of the API.
        this.setState({
            codes: await this.context.api.kh_code(),
            text: await this.context.api.kh_topic(),
            categories: await this.context.api.kh_categories(),
            data: await this.context.api.kh_get_by_category(this.state.curCategory),
            languages: await this.context.api.kh_get_languages()
        }, () => {
            // create graph
            let edges = this.state.categories.map(category => [parseInt(category.category_parent_id), parseInt(category.category_id), category]);

            let root = edges.find(edge => edge[0] === edge[1]);
            const pseudoRoots = edges.filter(edge => edge[0] === root[0] && edge[0] !== edge[1])
                .sort((e1, e2) => e1[2].category_order - e2[2].category_order)
                .map(edge => edge[1]);
            edges = edges.filter(edge => edge[0] !== edge[1]);

            if (root.length > 0) {
                root = root[0];
            }

            this.setState({
                graph: {
                    root: root,
                    edges: edges,
                    pseudoRoots: pseudoRoots
                }
            });
        });
    }

    async componentDidMount() {
        await this.loadData();
    }

    // TODO: implement
    async deleteCategory(event, category_id) {
        event.preventDefault();
        const response = await this.context.api.kh_delete_category(this.state.curCategory);

        if (response.success) {
            this.setState({
                curCategory: parseInt(this.state.categories[0].category_id)
            });

            await this.loadData();
        } else {
            this.setState({
                errorMessage: 'You can\'t delete category containing subcategories.'
            });
        }
    }

    async handleMove(target_category_id) {
        //console.log(`Moving from ${this.state.curCategory} to ${target_category_id}`);

        // different categories makes sense
        if (this.state.curCategory !== target_category_id) {
            const response = await this.context.api.kh_move_category(this.state.curCategory, target_category_id);
            if (!response.success) {
                this.setState({
                    errorMessage: 'You can\'t move category to its child category.'
                });
            }
        }

        this.setState({
            moveCategoryMode: false
        });

        await this.loadData();
    }

    async moveCategory(up) {
        await this.context.api.move_category_up_or_down(this.state.curCategory, up);
        await this.loadData();
    }

    async createCategory(event) {
        event.preventDefault();

        const categoryId = document.getElementById('category-id').value;
        const categoryText = document.getElementById('category-text').value;

        // update category
        if (categoryId) {
            // updating current category as well
            await this.context.api.kh_update_category(this.state.curCategory, categoryText);
            await this.loadData();
        }
        // create sub-category
        else {
            const categoryParentId = document.getElementById('is-root-category').checked && 1 || this.state.curCategory;
            const response = await this.context.api.kh_create_subcategory(categoryParentId, categoryText);
            const newCategoryId = parseInt(response.newCategoryId);

            await this.loadData();

            this.setState({
                curCategory: newCategoryId,
                data: await this.context.api.kh_get_by_category(newCategoryId)
            });
        }
    }

    render() {
        // user is not considered authenticated (no isLoggedIn flag in Local Storage) => redirect to login
        if (!this.context.consideredAuthenticated()) {
            return (<Navigate
                to={{
                    pathname: '/login',
                    state: {
                        toastMessage: 'You are already logged in.'
                    }
                }} />);
        }

        const g = this.state.graph;


        let topic, code;
        if (this.state.data !== undefined) {
            topic = this.state.data.topic;

            topic = topic.sort((t1, t2) => t1.topic_order - t2.topic_order);

            code = this.state.data.code;

            code = code.sort((c1, c2) => c1.code_order - c2.code_order);
        }

        const curCategory = this.state.categories.find(category => parseInt(category.category_id) === this.state.curCategory);

        // user is considered logged in => provide private content
        return (
            <>
                <div className={'row carousel-row'}>
                    <div className={'col-md-3'}>
                        <h5>Category tree</h5>

                        <div className={'tree category-tree-container'}>
                            {
                                this.state.categories !== undefined && this.state.graph.pseudoRoots.map((root, idx) => {
                                    return (
                                        <Tree
                                            key={'tree-' + idx}
                                            graph={g}
                                            curVertexId={root}
                                            parentId={null}
                                            categories={this.state.categories}
                                            presentCategory={this.state.curCategory}
                                            setCurCategory={async (id) => {
                                                this.setState({
                                                    curCategory: id,
                                                    data: await this.context.api.kh_get_by_category(id)
                                                });
                                            }
                                            }
                                            moveCategoryMode={this.state.moveCategoryMode}
                                            handleMove={this.handleMove.bind(this)}
                                        />
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className={'col-md-9'} style={{height: '100%'}}>
                        <Row>
                            <Col>
                                <Button
                                    style={{marginRight: '10px'}}
                                    variant={'success'}
                                    onClick={() => {
                                        this.setState({
                                            categoryFormShown: true,
                                            categoryFormData: {}
                                        });
                                    }}>
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </Button>

                                <Button
                                    style={{marginRight: '10px'}}
                                    variant={'secondary'}
                                    onClick={() => {
                                        this.setState({
                                            categoryFormShown: true,
                                            categoryFormData: {
                                                category_id: this.state.curCategory.toString(),
                                                category_name: curCategory.category_name
                                            }
                                        });
                                    }}>
                                    <FontAwesomeIcon icon={faWrench} />
                                </Button>

                                <Button
                                    style={{marginRight: '10px'}}
                                    variant={'danger'}
                                    onClick={() => {
                                        this.setState({
                                            deleteCategoryConfirmationDialogShown: true
                                        });
                                    }}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>


                                <span style={{marginRight: '10px'}}>
                                    <ButtonTooltip
                                        disabled={!this.state.moveCategoryMode}
                                        tooltipText={'Moving mode is not enabled.'}
                                        variant={'secondary'}
                                        onClick={() => {
                                            this.handleMove(1);
                                            this.setState({
                                                moveCategoryMode: false
                                            });
                                        }}>
                                        <FontAwesomeIcon icon={faTree} />
                                        {' '}Move to root
                                    </ButtonTooltip>
                                </span>

                                <span style={{marginRight: '10px'}}>
                                    <ButtonTooltip
                                        disabled={!this.state.moveCategoryMode}
                                        tooltipText={'Moving mode is not enabled.'}
                                        variant={'primary'}
                                        onClick={() => this.moveCategory(true)}>
                                        <FontAwesomeIcon icon={faArrowUp} />
                                    </ButtonTooltip>
                                </span>

                                <span style={{marginRight: '10px'}}>
                                    <ButtonTooltip
                                        disabled={!this.state.moveCategoryMode}
                                        tooltipText={'Moving mode is not enabled.'}
                                        variant={'primary'}
                                        onClick={() => this.moveCategory(false)}>
                                        <FontAwesomeIcon icon={faArrowDown} />
                                    </ButtonTooltip>
                                </span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: '10px', marginBottom: '10px'}}>
                            <Col>
                                <Form.Check
                                    type="switch"
                                    id="move-mode-switch"
                                    label="Moving mode"
                                    checked={this.state.moveCategoryMode}
                                    onChange={() => this.setState({
                                        moveCategoryMode: !this.state.moveCategoryMode
                                    })}
                                />
                            </Col>
                        </Row>

                        {![this.state.categories, topic, code].includes(undefined) && <Carousel
                            topic={topic}
                            code={code}
                            curCategory={this.state.curCategory}
                            reloadData={() => this.loadData()}
                            languages={this.state.languages} />}
                    </div>

                    <CategoryForm
                        hide={() => {
                            this.setState({
                                categoryFormShown: false
                            });
                        }}
                        isVisible={this.state.categoryFormShown}
                        onSubmit={this.createCategory.bind(this)}
                        formData={this.state.categoryFormData}
                    />

                    <ConfirmDialog
                        message={'Do you really want to permanently delete this category and all the corresponding topics and codes?'}
                        title={'Delete category confirmation'}
                        isVisible={this.state.deleteCategoryConfirmationDialogShown}
                        onSubmit={(event) => this.deleteCategory(event, this.state.curCategory)}
                        onCancel={() => this.setState({
                            deleteCategoryConfirmationDialogShown: false
                        })}
                    />

                    {this.state.errorMessage && (<Notification
                        header={'An error has occurred'}
                        body={this.state.errorMessage}
                        onClose={() => this.setState({
                            errorMessage: null
                        })}
                    />)
                    }

                </div>
            </>
        );
    }
}
