// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import {useNavigate} from 'react-router-dom';
import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import {AuthContext, AuthProvider} from '../context/AuthContext';

export default function Tree({ graph, curVertexId, parentId, categories, presentCategory, setCurCategory, moveCategoryMode, handleMove }) {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    function getNeigh() {
        const edges = graph.edges.filter(edge => edge[0] === curVertexId)
            .sort((e1, e2) => e1[2].category_order - e2[2].category_order);
        return edges.map(edge => edge[1]);
    }

    function findVertexObject() {
        return categories.find(category => parseInt(category.category_id) === curVertexId);
    }

    const neigh = getNeigh();
    const curVertexObject = findVertexObject();

    if (curVertexObject) {
        const current = curVertexId === presentCategory ? 'bold' : 'normal';
        const classes = `tree-node ${current}`;
        const x = (
            <li key={'li-' + curVertexId} className={classes} onClick={() => {
                if (moveCategoryMode) {
                    handleMove(curVertexId);
                }
            }}>
                <p
                    style={{color: moveCategoryMode && 'blue', fontStyle: moveCategoryMode && 'italic'}}
                    onClick={() => {
                        if (!moveCategoryMode) {
                            setCurCategory(curVertexId);
                        }
                    }}>
                    {curVertexObject.category_name}
                </p>
            </li>
        );
        if (neigh.length > 0) {
            return (
                <ul key={'ul-' + curVertexId}>
                    {x}
                    {
                        neigh.map(neighId => (
                            <Tree
                                graph={graph}
                                curVertexId={neighId}
                                parentId={curVertexId}
                                key={'tree-' + neighId}
                                categories={categories}
                                setCurCategory={setCurCategory}
                                presentCategory={presentCategory}
                                moveCategoryMode={moveCategoryMode}
                                handleMove={handleMove}/>
                        ))
                    }
                </ul>
            );
        } else {
            return (
                <ul>
                    {x}
                </ul>
            );
        }
    }
}

Tree.propTypes = {
    graph: PropTypes.object,
    curVertexId: PropTypes.number,
    parentId: PropTypes.number,
    categories: PropTypes.array,
    presentCategory: PropTypes.number,
    setCurCategory: PropTypes.func,
    moveCategoryMode: PropTypes.bool,
    handleMove: PropTypes.func
};
