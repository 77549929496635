// Bootstrap
import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Form} from 'react-bootstrap';
import {AuthContext} from '../context/AuthContext';

import { Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';
import TopicForm from './TopicForm';
import CodeForm from './CodeForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faExpand,
    faLeftLong, faPlusCircle,
    faRightLong, faTrash,
    faWrench
} from '@fortawesome/free-solid-svg-icons';
import ConfirmDialog from './ConfirmDialog';
import Notification from './Notification';
// import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export default function Carousel({ topic, code, curCategory, reloadData, languages }) {
    const authContext = useContext(AuthContext);

    const [state, setState] = useState({});

    const [curTopicIdx, setTopicIdx] = useState(parseInt(localStorage.getItem('lastTopicIdx') || 0));
    const [curCodeIdx, setCodeIdx] = useState(parseInt(localStorage.getItem('lastCodeIdx') || 0));

    useEffect(() => {
        if (localStorage.getItem('newTopicIdx') === null) {
            return;
        }

        const newTopicIdx = parseInt(localStorage.getItem('newTopicIdx'));
        if (newTopicIdx < topic.length) {
            setTopicIdx(newTopicIdx);
            localStorage.removeItem('newTopicIdx');
        }
    }, [topic]);

    // filter the topic's codes
    if (curTopicIdx !== 0 && curTopicIdx >= topic.length) {
        setTopicIdx(0);
        return null;
    }

    const filteredCodesForTopic = code.filter(c => c.topic_id === topic[curTopicIdx].topic_id);

    useEffect(() => {
        if (localStorage.getItem('newCodeIdx') === null) {
            return;
        }

        const newCodeIdx = parseInt(localStorage.getItem('newCodeIdx'));
        if (newCodeIdx < filteredCodesForTopic.length) {
            setCodeIdx(newCodeIdx);
            localStorage.removeItem('newCodeIdx');
        }
    }, [code]);

    if (curCodeIdx !== 0 && curCodeIdx >= code.length) {
        setCodeIdx(0);
        return null;
    }

    localStorage.setItem('lastCategoryIdx', curCategory);
    localStorage.setItem('lastTopicIdx', curTopicIdx);
    localStorage.setItem('lastCodeIdx', curCodeIdx);

    let codeView;
    // no 'codes' for current topic
    if (filteredCodesForTopic.length === 0) {
        codeView = (
            <>
                <p><em>No codes available</em></p>
            </>
        );
    } else {
        codeView = (
            <>
                <h4>{filteredCodesForTopic[curCodeIdx].code_name}</h4>
                <SyntaxHighlighter language={filteredCodesForTopic[curCodeIdx].language_name} style={prism}>
                    {filteredCodesForTopic[curCodeIdx].code_text}
                </SyntaxHighlighter>
                {/*<p style={{whiteSpace: 'pre-wrap'}}>*/}
                {/*    {filteredCodesForTopic[curCodeIdx].code_text}*/}
                {/*</p>*/}
            </>
        );
    }

    const [topicData, setTopicData] = useState({});

    const [showNewTopic, setShowNewTopic] = useState(false);
    const handleCloseNewTopic = () => {
        setTopicData({});
        setShowNewTopic(false);
    };

    const handleShowNewTopic = () => setShowNewTopic(true);

    const [deleteTopicConfirmationModalVisible, setDeleteTopicConfirmationModalVisible] = useState(false);
    const [deleteCodeConfirmationModalVisible, setDeleteCodeConfirmationModalVisible] = useState(false);

    const [showNewCode, setShowNewCode] = useState(false);
    const handleCloseNewCode = () => {
        setCodeData({});
        setShowNewCode(false);
    };
    const handleShowNewCode = () => setShowNewCode(true);
    const [codeData, setCodeData] = useState({});

    let topicHeader = (
        <div>
            <div>
                <h2 style={{display: 'inline-block'}}>Topic ({topic.length > 0 && curTopicIdx + 1 || 0}/{topic.length > 0 && topic.length || 0})</h2>
                <Button
                    style={{float: 'right'}}
                    variant={'danger'}
                    onClick={() => setDeleteTopicConfirmationModalVisible(true)}
                    disabled={topic.length === 0}>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
                <Button
                    style={{float: 'right', marginRight: '10px'}}
                    variant={'secondary'}
                    onClick={() => {
                        setTopicData({
                            topic_id: topic[curTopicIdx].topic_id,
                            topic_text: topic[curTopicIdx].topic_text,
                        });
                        handleShowNewTopic();
                    }}
                    disabled={topic.length === 0}>
                    <FontAwesomeIcon icon={faWrench} />
                </Button>
                <Button
                    style={{float: 'right', marginRight: '10px'}}
                    variant={'success'}
                    onClick={handleShowNewTopic}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                </Button>
            </div>
            <div>
                <Button
                    className={'float-end'}
                    onClick={() => {
                        setTopicIdx(curTopicIdx + 1);
                        setCodeIdx(0);
                    }}
                    disabled={curTopicIdx >= topic.length - 1}>
                    <FontAwesomeIcon icon={faRightLong} />
                </Button>
                <Button
                    className={'float-end'}
                    style={{marginRight: '10px'}}
                    onClick={() => {
                        setTopicIdx(curTopicIdx - 1);
                        setCodeIdx(0);
                    }}
                    disabled={curTopicIdx === 0}>
                    <FontAwesomeIcon icon={faLeftLong} />
                </Button>
                <Button
                    className={'float-end'}
                    style={{marginRight: '10px'}}
                    onClick={(evt) => document.getElementById('topic-container').classList.toggle('fullscreen')}>
                    <FontAwesomeIcon icon={faExpand} />
                </Button>
            </div>
            <br/>
            <br/>
            <hr/>
        </div>
    );

    let codeHeader = (
        <div>
            <div>
                <h2 style={{display: 'inline-block'}}>Code ({filteredCodesForTopic.length > 0 && curCodeIdx + 1 || 0}/{filteredCodesForTopic.length > 0 && filteredCodesForTopic.length || 0})</h2>
                <Button
                    style={{float: 'right'}}
                    variant={'danger'}
                    onClick={() => setDeleteCodeConfirmationModalVisible(true)}
                    disabled={filteredCodesForTopic.length === 0}>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
                <Button
                    style={{float: 'right', marginRight: '10px'}}
                    variant={'secondary'}
                    onClick={() => {
                        setCodeData({
                            code_id: filteredCodesForTopic[curCodeIdx].code_id,
                            code_name: filteredCodesForTopic[curCodeIdx].code_name,
                            code_text: filteredCodesForTopic[curCodeIdx].code_text,
                            code_language_id: filteredCodesForTopic[curCodeIdx].code_language_id,
                        });
                        handleShowNewCode();
                    }}
                    disabled={filteredCodesForTopic.length === 0}>
                    <FontAwesomeIcon icon={faWrench} />
                </Button>
                <Button
                    style={{float: 'right', marginRight: '10px'}}
                    variant={'success'}
                    onClick={handleShowNewCode}
                    disabled={topic.length === 0}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                </Button>
            </div>
            <div>
                <Button
                    className={'float-end'}
                    onClick={() => {
                        setCodeIdx(curCodeIdx + 1);
                    }}
                    disabled={curCodeIdx >= filteredCodesForTopic.length - 1}>
                    <FontAwesomeIcon icon={faRightLong} />
                </Button>
                <Button
                    className={'float-end'}
                    style={{marginRight: '10px'}}
                    onClick={() => {
                        setCodeIdx(curCodeIdx - 1);
                    }}
                    disabled={curCodeIdx === 0}>
                    <FontAwesomeIcon icon={faLeftLong} />
                </Button>
                <Button
                    className={'float-end'}
                    style={{marginRight: '10px'}}
                    onClick={(evt) => document.getElementById('code-container').classList.toggle('fullscreen')}>
                    <FontAwesomeIcon icon={faExpand} />
                </Button>
            </div>
            <br/><br/>
            <hr/>
        </div>
    );

    let topicView;
    if (topic.length === 0) {
        topicView = (
            <p><em>No topic available</em></p>
        );
    } else {
        topicView = (<p style={{whiteSpace: 'pre-wrap'}}>{topic[curTopicIdx].topic_text}</p>);
    }

    const createTopic = async (event) => {
        event.preventDefault();

        const topicId = document.getElementById('topic-id').value;
        const topicText = document.getElementById('topic-text').value;

        // save topic
        const response = await authContext.api.kh_create_topic(topicId, curCategory, topicText);

        // successfully created new topic => navigate to it
        if (response.success === 'true' && !topicId) {
            localStorage.setItem('newTopicIdx', topic.length);
            console.log('here');
        }

        reloadData();
    };

    const createCodeForTopic = async (event) => {
        event.preventDefault();

        const codeId = document.getElementById('code-id').value;
        const codeName = document.getElementById('code-name').value;
        const codeText = document.getElementById('code-text').value;
        const codeLanguageId = document.getElementById('code-language').value;

        // save topic
        const response = await authContext.api.kh_create_code_for_topic(codeId, topic[curTopicIdx].topic_id, codeName, codeLanguageId, codeText);

        if (response.success === 'true' && !codeId) {
            localStorage.setItem('newCodeIdx', filteredCodesForTopic.length);
            reloadData();
        } else if (response.success === 'true' && codeId) {
            reloadData();
        } else if (response.success === 'false') {
            const errorMessage = response.errorMessage;
            setState({
                errorMessage: errorMessage
            });
        }
    };

    const deleteTopic = async (event, topicId) => {
        event.preventDefault();

        setTopicIdx(Math.max(0, curTopicIdx - 1));

        await authContext.api.kh_delete_topic_and_its_codes(topicId);

        reloadData();
    };

    const deleteCode = async (event, codeId) => {
        event.preventDefault();

        setCodeIdx(curCodeIdx - 1);

        await authContext.api.kh_delete_code(codeId);
        reloadData();
    };

    return (
        <div className={'topic-code-container'}>
            {state.errorMessage && (<Notification
                header={'An error has occurred'}
                body={state.errorMessage}
                onClose={() => setState({})}
            />)
            }

            <TopicForm
                reloadData={reloadData}
                hide={() => handleCloseNewTopic()}
                isVisible={showNewTopic}
                onSubmit={createTopic}
                formData={topicData}
            />

            <CodeForm
                reloadData={reloadData}
                hide={() => handleCloseNewCode()}
                isVisible={showNewCode}
                onSubmit={createCodeForTopic}
                formData={codeData}
            />

            {/* Topic delete confirmation dialog */}
            <ConfirmDialog
                message={'Do you really want to permanently delete this topic & all the corresponding codes?'}
                title={'Delete topic confirmation'}
                isVisible={deleteTopicConfirmationModalVisible}
                onSubmit={(event) => deleteTopic(event, topic[curTopicIdx].topic_id)}
                onCancel={() => setDeleteTopicConfirmationModalVisible(false)}
            />

            {/* Code delete confirmation dialog */}
            <ConfirmDialog
                message={'Do you really want to permanently delete this code?'}
                title={'Delete code confirmation'}
                isVisible={deleteCodeConfirmationModalVisible}
                onSubmit={(event) => deleteCode(event, filteredCodesForTopic[curCodeIdx].code_id)}
                onCancel={() => setDeleteCodeConfirmationModalVisible(false)}
            />

            <div className={'jumbotron-container allow-vertical-scrollbar'} id={'topic-container'} style={{flex: '1 1 0'}}>
                {topicHeader}

                {topicView}
            </div>
            <div className={'jumbotron-container allow-vertical-scrollbar'} id={'code-container'} style={{flex: '1 1 0'}}>
                {codeHeader}

                <pre>
                    {codeView}
                </pre>
            </div>
        </div>
    );
}

Carousel.propTypes = {
    topic: PropTypes.array,
    code: PropTypes.array,
    curCategory: PropTypes.number,
    reloadData: PropTypes.func,
    languages: PropTypes.array
};
